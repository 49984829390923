import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";

import './App.css';

import Home from './components/Home';
import Whyus from './components/Whyus';
import Faq from './components/Faq';
import Footer from './components/Footer';

import About from './components/About'
import Pricing from './components/Pricing';
import Contact from './components/Contact';

import NotFound from './components/NotFound';

import GoUpButton from './components/GoUpButton';

function App() {
  return (
    <Switch>
      <Route exact path="/" 
        render={() =>
          <>
                  <Home />
                  <Whyus />
                  <Faq />
                  <Footer />
                  <GoUpButton />
          </>
        }
      />
      <Route exact path="/apropos">
            <About />
      </Route>
      <Route path="/tarifs" component={Pricing} />
      <Route path="/contact" component={Contact} />
      <Route path="*">
            <NotFound />
      </Route>
    </Switch>     
  );
}

export default App;