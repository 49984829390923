import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from '../WorldLocationLogo.png'

import {
    useLocation,
    Link
  } from "react-router-dom";

import { Link as Anchor, Element } from 'react-scroll';

var navigation = []

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation();

 switch (location.pathname) {
    case '/apropos':
        navigation= [
        { name: 'Accueil', href: '/', Anchor: false },
        { name: 'Qui sommes nous ?', href: 'apropos', Anchor: false },
        /*{ name: 'Tarifs', href: 'tarifs', Anchor: false },*/
        { name: 'Nous contacter', href: 'contact', Anchor: false },
    ];
    break;
    case '/tarifs':
        navigation= [
        { name: 'Accueil', href: '/', Anchor: false },
        { name: 'Qui sommes nous ?', href: 'apropos', Anchor: false },
        { name: 'Tarifs', href: 'tarifs', Anchor: false },
        { name: 'Nous contacter', href: 'contact', Anchor: false },
    ];
    break;
    case '/contact':
        navigation= [
        { name: 'Accueil', href: '/', Anchor: false },
        { name: 'Qui sommes nous ?', href: 'apropos', Anchor: false },
        /*{ name: 'Tarifs', href: 'tarifs', Anchor: false },*/
        { name: 'Nous contacter', href: 'contact', Anchor: false },
    ];
    break;
    default:
        navigation= [
            { name: 'Accueil', href: '/', Anchor: false },
            { name: 'Qui sommes nous ?', href: '/apropos', Anchor: false },
            { name: 'Pourquoi nous choisir ?', href: '/', Anchor: true, to: 'whyus' },
            { name: 'FAQ', href: '/', Anchor: true, to: 'FAQ'},
            /*{ name: 'Tarifs', href: 'tarifs', Anchor: false },*/
            { name: 'Nous contacter', href: '/contact', Anchor: false },
        ];
 }

  return (
    <Element name='Navbar'>
    <div className="bg-white">
        <header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div className="flex lg:flex-1">
                <Link
                    to="/"
                    className="-m-1.5 p-1.5"
                >
                <span className="sr-only">World Location</span>
                <img
                    className="h-24 w-auto"
                    src={Logo}
                    alt=""
                />
                </Link>
            </div>
            <div className="flex lg:hidden">
                <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
                >
                <span className="sr-only">Ouvrir le menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map(item => {
                        return item.Anchor === true ?
                    <Anchor activeClass="active" 
                        to={item.to}
                        spy={true} 
                        smooth={true} 
                        offset={50} 
                        duration={500} 
                        key={item.name}
                        className="text-sm font-semibold leading-6 text-gray-900 textHoverColor">
                            {item.name}
                    </Anchor>
                        :
                    <Link
                        to={item.href}
                        className="text-sm font-semibold leading-6 text-gray-900 textHoverColor"
                    >
                            {item.name}
                    </Link>
                })}
            </div>
                
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                {/* Log in here if needed */}  
            </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">World location</span>
                    <img
                    className="h-20 w-auto"
                    src={Logo}
                    alt=""
                    />
                </a>
                <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                </div>
                <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                {navigation.map(item => {
                        return item.Anchor === true ?
                        null
                        :
                    <Link
                        to={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 textHoverColor"
                    >
                            {item.name}
                    </Link>
                })}
                    </div>
                </div>
                </div>
            </Dialog.Panel>
            </Dialog>
        </header>
    </div>
    </Element>
  )
}