import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-scroll';

export default function GoUpButton() {
  return (
    <>
        <Link activeClass="active"
                        to="Navbar"
                        spy={true} 
                        smooth={true} 
                        offset={50} 
                        duration={500} 
                        className="GoUpbutton inline-flex items-center gap-x-1.5 rounded-lg bg-indigo-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <div class="h-0 w-0 border-x-8 border-x-transparent border-b-[16px]"></div>
        </Link>
    </>
  )
}
