import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { Element } from 'react-scroll';

const faqs = [
  {
    question: "Comment savoir si je peux profiter du service de World Location ?",
    answer:
      "Contactez-nous et si besoin rencontrons-nous. C’est le moyen le plus efficace pour vérifier si votre bien entre dans nos critères d’éligibilité ! Un formulaire de contact se trouve juste en haut dans le menu de cette page, alors à tout de suite.",
  },
  
  {
    question: "Est-ce que World Location est une agence immobilière ?",
    answer:
      "Non ! Avec nous aucuns frais d’agence ni de commissions. Nous réalisons pour vous de la gestion locative, nous louons votre bien pour le mettre à disposition de notre clientèle de professionnels et de voyageurs haut de gamme. Que votre bien soit loué ou non, vous recevrez votre argent en temps et en heure !",
  },
  
  {
    question: "Quelles différences entre un bail d'habitation classique et le bail de World Location ?",
    answer:
      "Comme vous le savez, lorsque vous louez votre bien à un particulier, vous vous engagez sur une durée indéterminée. Pour récupérer votre bien, un préavis de quelques mois s’applique. Notre bail est un bail dérogatoire entre vous, propriétaire et nous, société. Les clauses du contrat World Location permettent au preneur et au bailleur de s’en libérer à tout moment, en observant un préavis de seulement quelques semaines. Fini les trêves hivernales, voici un bail bien plus flexible qui offre plus de sécurité aux propriétaires.",
  },

  {
    question: "Comment mes loyers sont garantis ?",
    answer:
      "Le paiement de votre loyer est notre priorité et notre engagement n°1. En effet, votre appartement est notre fonds de commerce. En vous réglant un loyer régulier en début de mois, nous garantissons la pérennité de notre entreprise. World Location s’engage, que votre bien soit occupé ou non. Soyez donc rassurés, vous venez de trouver le locataire idéal !",
  },

  {
    question: "Comment le bon entretien de mon bien est-il garanti ?",
    answer:
      "Très régulièrement, du personnel d’entretien est sollicité pour établir un état des lieux, contrôler que tout est en ordre et faire en sorte que le logement soit dans un état irréprochable. C’est la garantie pour vous, d’un investissement parfaitement entretenu et donc de bénéfices au court et long terme.",
  },
]

export default function Faq() {
  return (
    <Element name='FAQ'>
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight textColor">Questions fréquemment posées</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
    </Element>
  )
}
